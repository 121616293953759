import ReactGA from 'react-ga4'

export enum EVENT_CATEGORY {
    LESSON = 'Lesson',
}

export enum EVENT_ACTION {
    CLICK = 'Click',
    PROGRESS = 'Progress',
}

export function isInitialized() {
    const w = window as any
    return w.dataLayer ? true : false
}

export function trackEvent(category: EVENT_CATEGORY, action: EVENT_ACTION, label: string, value?: number) {
    const event = new CustomEvent('trackUserActivity', {
        detail: { category: EVENT_CATEGORY, action: EVENT_ACTION, label: label, value: value ? value : '' },
    })
    document.dispatchEvent(event)
    ReactGA.event({
        category: category,
        action: action,
        label: label,
        value: value,
    })
}

export function trackPageView() {
    ReactGA.send({
        hitType: 'pageview',
        page: document.location.hash.replace('#', ''),
    })
}
