import React, { Component } from 'react'

import { getClientUrl } from '../helpers/url'

import DataProtectionContent from '../content/DataProtectionContent'
import ClientLayout from '../layout/ClientLayout'

interface State {
    dataProtectionText: string | null
}

export default class DataProtectionScreen extends Component<Record<string, never>, State> {
    state = { dataProtectionText: null } as State

    async componentDidMount() {
        const response = await fetch(getClientUrl('/data_protection_content'))
        const { text } = await response.json()

        this.setState({
            dataProtectionText: text,
        })
    }

    getDataProtectionContent() {
        const { dataProtectionText } = this.state
        if (dataProtectionText === null) {
            return <DataProtectionContent></DataProtectionContent>
        }
        return <div dangerouslySetInnerHTML={{ __html: dataProtectionText }}></div>
    }

    render() {
        const content = this.getDataProtectionContent()
        return (
            <ClientLayout
                basicLayoutProps={
                    {
                        props: {
                            container: {
                                className: 'main-content-container lesson-article-container',
                            },
                        },
                    } as any
                }
                onReceiveData={() => {}}
            >
                <section className="section-article-content">{content}</section>
            </ClientLayout>
        )
    }
}
