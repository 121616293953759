import React, { Component } from 'react'
import { Col, Row } from 'react-bootstrap'
import { RouteComponentProps } from 'react-router-dom'

import LessonCard from '../components/LessonCardV2'
import LessonHeader from '../components/LessonHeader'
import LessonIntro from '../components/LessonIntro'
import TermsAndConditions from '../components/TermsAndConditions'
import ClientLayout, { ClientData, ClientLesson } from '../layout/ClientLayout'
import { trackEvent, EVENT_CATEGORY, EVENT_ACTION } from '../helpers/track'

interface State {
    loading: boolean
    data?: ClientData
    showTerms: boolean
    pendingLessonId?: string
}

type Props = RouteComponentProps<any>

export default class IndexScreen extends Component<Props, State> {
    state = {
        loading: true,
        data: undefined,
        showTerms: false,
    } as State

    renderItem(item: ClientLesson) {
        return (
            <Col xl={4} lg={4} md={6} sm={12} key={`item-${item.id}`}>
                <LessonCard
                    key={item.id}
                    onClick={this.onClick}
                    id={item.id}
                    title={item.header}
                    description={item.description}
                    icon={item.visual}
                    themeColor={this.state.data?.theme_color}
                />
            </Col>
        )
    }

    render() {
        const { data, showTerms } = this.state

        const normalLessons = data?.lessons.filter((item) => !item.is_bonus) || []
        const bonusLessons = data?.lessons.filter((item) => item.is_bonus) || []

        return (
            <ClientLayout onReceiveData={(data) => this.setState({ data })}>
                <section className="course-header-section">
                    <LessonHeader
                        headerVideoUrl={data?.header_video}
                        headerTitle={data?.header_title}
                        headerTitle2={data?.header_title2}
                        headerTitle3={data?.header_title3}
                        headerTitle4={data?.header_title4}
                        headerTitle5={data?.header_title5}
                        headerTitle6={data?.header_title6}
                        introVideoUrl={data?.intro_video}
                        headerImage={data?.header_image}
                    />
                </section>

                {data?.intro_video ? (
                    <section className="course-intro-section" id="course-intro-section">
                        <LessonIntro
                            introVideoUrl={data?.intro_video || null}
                            introTitle={data?.intro_title}
                            introDescription={data?.intro_description}
                        />
                    </section>
                ) : null}

                {normalLessons.length > 0 ? (
                    <section className="course-list-section" id="course-list-section">
                        <div className="course-list-section-inner">
                            <h3>Übersicht</h3>
                            <Row className="lesson-index-row">
                                {normalLessons.map((item: ClientLesson) => this.renderItem(item))}
                            </Row>
                        </div>
                    </section>
                ) : null}

                {bonusLessons.length > 0 ? (
                    <section className="course-list-section course-list-bonus-section">
                        <div className="course-list-section-inner">
                            <h3>Bonus</h3>
                            <Row className="lesson-index-row">
                                {bonusLessons.map((item: ClientLesson) => this.renderItem(item))}
                            </Row>
                        </div>
                    </section>
                ) : null}

                {data?.terms !== null ? (
                    <TermsAndConditions
                        onTermsAgree={this.onTermsAgree}
                        onTermsDecline={this.onTermsDecline}
                        open={showTerms}
                    />
                ) : null}
            </ClientLayout>
        )
    }

    onTermsDecline = () => {
        localStorage.setItem('termAndConditionsCheck', '0')
        this.setState({ showTerms: false })
    }

    onTermsAgree = () => {
        localStorage.setItem('termAndConditionsCheck', '1')

        this.setState({ showTerms: false })

        this.onClick(this.state.pendingLessonId as string)
    }

    onClick = (id: string) => {
        const { data } = this.state

        if (!data) {
            return
        }

        const agreedTerms = localStorage.getItem('termAndConditionsCheck') || '0'

        if (agreedTerms === '0' && data.terms !== null) {
            this.setState({ showTerms: true, pendingLessonId: id })
        } else {
            this.props.history.push(`/lesson/${id}`)
        }
        const [lessonItem] = data.lessons.filter((lesson) => lesson.id === id)

        trackEvent(EVENT_CATEGORY.LESSON, EVENT_ACTION.CLICK, lessonItem.header)
    }
}
