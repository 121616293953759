import React, { Component } from 'react'

import ImprintContent from '../content/ImprintContent'
import ClientLayout from '../layout/ClientLayout'

export default class ImprintScreen extends Component {
    state = {}

    render() {
        return (
            <ClientLayout
                basicLayoutProps={
                    {
                        props: {
                            container: {
                                className: 'main-content-container lesson-article-container',
                            },
                        },
                    } as any
                }
                onReceiveData={() => {}}
            >
                <section className="section-article-content">
                    <ImprintContent />
                </section>
            </ClientLayout>
        )
    }
}
