import React, { Component } from 'react'
import { Redirect, RouteComponentProps } from 'react-router-dom'

import { getChapterRedirectUrl } from '../helpers/url'

interface State {
    lesson_id: string | undefined
}

interface RouteParams {
    number: string
}

type Props = RouteComponentProps<RouteParams>

export default class LessonScreen extends Component<Props, State> {
    state = { lesson_id: undefined } as State

    async componentDidMount() {
        const response = await fetch(getChapterRedirectUrl(this.props.match.params.number))
        const lesson_id = await response.json()
        this.setState({ lesson_id: lesson_id })
    }

    render() {
        if (this.state.lesson_id !== undefined) {
            if (this.state.lesson_id !== '') {
                return <Redirect to={`/lesson/${this.state.lesson_id}`} />
            } else {
                return <Redirect to="/" />
            }
        }
        return null
    }
}
