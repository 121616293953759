import './index.css'

import React, { Component } from 'react'
import { Button, Col, Row, ThemeProvider } from 'react-bootstrap'
import { isBrowser, isMobile, isIE } from 'react-device-detect'

interface Props {
    headerVideoUrl?: string | null
    headerTitle?: string | null
    headerTitle2?: string | null
    headerTitle3?: string | null
    headerTitle4?: string | null
    headerTitle5?: string | null
    headerTitle6?: string | null
    introVideoUrl?: string | null
    headerImage?: string
}
interface State {
    height: number
    currentHeader: string
    currentHeaderIndex: number
    sliderClass: string
}

export default class LessonHeader extends Component<Props, State> {
    state = {
        height: 0,
        currentHeader: '',
        currentHeaderIndex: -1,
        sliderClass: 'slide-in',
    }

    componentDidMount = () => {
        window.addEventListener('resize', () => {
            if (isIE) {
                this.setState({
                    height: window.innerHeight,
                })
            } else {
                this.setState({
                    height: window.innerHeight - 64,
                })
            }
        })
        if (isIE) {
            this.setState({
                height: window.innerHeight,
            })
        } else {
            this.setState({
                height: window.innerHeight - 64,
            })
        }
        window.setTimeout(this.toggleHeaderTitle, 50)
    }

    private onClickStart = () => {
        const sectionToScroll = window.document.getElementById('course-list-section')

        if (sectionToScroll) {
            window.scrollTo(0, sectionToScroll?.offsetTop - 75)
        }
    }

    private onClickIntro = () => {
        const sectionToScroll = window.document.getElementById('course-intro-section')

        if (sectionToScroll) {
            window.scrollTo(0, sectionToScroll?.offsetTop - 75)
        }
    }

    private renderHeaderContent() {
        if (isMobile) {
            return <img src={this.props.headerImage} className="lesson-header-image" />
        } else if (this.props.headerVideoUrl && isBrowser) {
            return (
                <React.Fragment>
                    <video
                        className="lesson-header-video"
                        loop={true}
                        muted={true}
                        playsInline={true}
                        autoPlay={true}
                        preload="metadata"
                    >
                        <source src={this.props.headerVideoUrl} type="video/mp4" />
                        <source src={this.props.headerVideoUrl} type="video/ogg" />
                        Dein Browser unterstützt keine HTML5 Videos.
                    </video>
                    <div className="lesson-header-video video-overlay"></div>
                </React.Fragment>
            )
        } else {
            return <img src={this.props.headerImage} className="lesson-header-image" />
        }
    }

    getHeaderList = () => {
        const headerList = []
        if (this.props.headerTitle) {
            headerList.push(this.props.headerTitle)
        }
        if (this.props.headerTitle2) {
            headerList.push(this.props.headerTitle2)
        }
        if (this.props.headerTitle3) {
            headerList.push(this.props.headerTitle3)
        }
        if (this.props.headerTitle4) {
            headerList.push(this.props.headerTitle4)
        }
        if (this.props.headerTitle5) {
            headerList.push(this.props.headerTitle5)
        }
        if (this.props.headerTitle6) {
            headerList.push(this.props.headerTitle6)
        }
        return headerList
    }

    toggleHeaderTitle = () => {
        const headerList = this.getHeaderList()
        let currentIndex = this.state.currentHeaderIndex + 1
        if (currentIndex >= headerList.length) {
            currentIndex = 0
        }
        const currentHeader = headerList[currentIndex]
        this.setState({ currentHeaderIndex: currentIndex, currentHeader: currentHeader, sliderClass: 'slide-in' })
        if (headerList.length > 1) {
            window.setTimeout(() => {
                this.setState({ sliderClass: 'slide-out' })
            }, 3500)
            window.setTimeout(this.toggleHeaderTitle, 4000)
        }
    }

    render() {
        return (
            <ThemeProvider prefixes={{ btn: 'lesson-header-btn' }}>
                <div className="lesson-header-container jumbotron" style={{ height: this.state.height }}>
                    {this.renderHeaderContent()}

                    {this.props.headerVideoUrl ? (
                        <div className={this.state.sliderClass + ' lesson-header-header'}>
                            <h1 dangerouslySetInnerHTML={{ __html: this.state.currentHeader as any }}></h1>
                        </div>
                    ) : null}
                    <Row className="justify-content-md-center lesson-header-buttons">
                        {this.props.introVideoUrl ? (
                            <React.Fragment>
                                <Col style={{ padding: 1 }} md={{ span: 3 }} sm={12}>
                                    <Button
                                        variant="success"
                                        size="lg"
                                        className="btn-block"
                                        onClick={this.onClickIntro}
                                    >
                                        EINFÜHRUNG
                                    </Button>
                                </Col>
                                <Col style={{ padding: 1 }} md={{ span: 3, offset: 2 }} sm={12}>
                                    <Button
                                        variant="primary"
                                        size="lg"
                                        className="btn-block"
                                        onClick={this.onClickStart}
                                    >
                                        JETZT STARTEN
                                    </Button>
                                </Col>
                            </React.Fragment>
                        ) : (
                            <Col style={{ padding: 1 }} md={{ span: 3 }} sm={12}>
                                <Button variant="primary" size="lg" className="btn-block" onClick={this.onClickStart}>
                                    JETZT STARTEN
                                </Button>
                            </Col>
                        )}
                    </Row>
                </div>
            </ThemeProvider>
        )
    }
}
