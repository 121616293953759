import React, { Component, createContext } from 'react'

export interface GlobalStateType {
    jsInjectionDone: boolean
}

type Dispatch<A> = (value: A) => void
export type GlobalStateSetterType = Dispatch<GlobalStateType> | null

export interface GlobalStateContextType {
    globalState: GlobalStateType
    globalStateSetter: GlobalStateSetterType
}

const initialGlobalState = { jsInjectionDone: false } as GlobalStateType

const initialGlobalStateContext = {
    globalState: initialGlobalState,
} as GlobalStateContextType

export const GlobalStateContext = createContext<GlobalStateContextType>(initialGlobalStateContext)

interface Props {
    children?: React.ReactNode | React.ReactNode[]
}

export default class GlobalStateProvider extends Component<Props, GlobalStateType> {
    state = initialGlobalState
    render() {
        const provide = {
            globalState: this.state,
            globalStateSetter: (state: GlobalStateType) => this.setState(state),
        }
        return <GlobalStateContext.Provider value={provide}>{this.props.children}</GlobalStateContext.Provider>
    }
}
