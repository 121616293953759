function getClientNameByHostname() {
    const url = new URL(document.location.href)
    const fragments = url.hostname.split('.', 1)

    return fragments.length === 1 ? fragments[0] : null
}

function getClientNameBySearchParams() {
    const query = new URLSearchParams(document.location.search)
    return query.has('client') ? query.get('client') : null
}

function getClientName() {
    const clientName = getClientNameBySearchParams() || getClientNameByHostname()

    if (!clientName) throw new Error('Unable to identify client context')

    return clientName
}

export function getClientUrl(path: string): string {
    const clientName = getClientName()
    return `/api/client/${clientName}${path}`
}

export function getLessonUrl(id: string): string {
    return `/lessons/${id}/index.html`
}

export function getChapterRedirectUrl(chapter: string): string {
    const clientName = getClientName()
    return `/api/client/${clientName}/chapter/${chapter}`
}
