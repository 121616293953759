import './index.css'

import React, { Component } from 'react'
import { Row } from 'react-bootstrap'

interface Props {
    introVideoUrl?: string | null
    introTitle?: string
    introDescription?: string | null
}

export default class LessonIntro extends Component<Props> {
    private renderIntroDescription() {
        if (this.props.introDescription) {
            return this.props.introDescription.split('\n').map((item) => {
                return <p key={`description-line`}>{item}</p>
            })
        }
    }

    render() {
        return (
            <React.Fragment>
                <h3>{this.props.introTitle}</h3>
                {this.renderIntroDescription()}

                <Row className="lesson-index-video-row">
                    {this.props.introVideoUrl ? (
                        <video
                            className="lesson-video"
                            playsInline={true}
                            loop={false}
                            muted={false}
                            autoPlay={false}
                            controls={true}
                            preload="metadata"
                        >
                            <source src={this.props.introVideoUrl} type="video/mp4" />
                            <source src={this.props.introVideoUrl} type="video/ogg" />
                            Dein Browser unterstützt keine HTML5 Videos.
                        </video>
                    ) : null}
                </Row>
            </React.Fragment>
        )
    }
}
