import './index.css'

import { Line } from 'rc-progress'
import React, { Component } from 'react'
import { Button, Card } from 'react-bootstrap'

interface Props {
    title: string
    description: string
    icon: string
    id: string
    onClick: (id: string) => void
    themeColor: any
}
interface State {
    percentComplete: number
}

export default class LessonCard extends Component<Props, State> {
    state = {
        percentComplete: 0,
    }

    onClick = (reset = false) => {
        if (reset) {
            localStorage.removeItem(`lesson.progress.${this.props.id}`)
            localStorage.removeItem(`lesson.progress.${this.props.id}.sum`)
        }

        this.props.onClick(this.props.id)
    }

    render() {
        const { description, icon, title, id, themeColor } = this.props

        const percentComplete = parseInt(localStorage.getItem(`lesson.progress.${id}.sum`) as string)

        return (
            <Card className="card-lesson card-lesson-complete">
                <div className="card-img-container">
                    <img className="card-img-top" src={icon} alt="Card image cap" />
                    <div className="card-img-shadow"></div>
                    {percentComplete > 0 && percentComplete < 100 ? (
                        <div className="card-img-progress">
                            <Line
                                trailColor={'gray'}
                                strokeColor={themeColor}
                                strokeWidth={2}
                                percent={percentComplete || 0}
                            />
                        </div>
                    ) : null}
                </div>
                <Card.Body className="card-body-cascade">
                    <h5 className="card-title">
                        <strong>{title}</strong>
                    </h5>

                    <p className="card-text">{description}</p>
                </Card.Body>
                <Card.Footer>
                    {percentComplete > 0 ? (
                        percentComplete === 100 ? (
                            <Button
                                variant="outline-success"
                                block={true}
                                size={'lg'}
                                onClick={() => this.onClick(true)}
                            >
                                Kurs neu starten
                            </Button>
                        ) : (
                            <Button variant="outline-dark" block={true} size={'lg'} onClick={() => this.onClick()}>
                                Fortfahren
                            </Button>
                        )
                    ) : (
                        <Button onClick={() => this.onClick()} variant="outline-dark" block={true} size={'lg'}>
                            Jetzt starten
                        </Button>
                    )}
                </Card.Footer>
            </Card>
        )
    }
}
