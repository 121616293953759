import 'bootstrap/dist/css/bootstrap.min.css'
import './App.css'

import React from 'react'
import { HashRouter as Router, Route } from 'react-router-dom'

import ChapterRedirect from './screens/ChapterRedirect'
import DataProtectionScreen from './screens/DataProtectionScreen'
import ImprintScreen from './screens/ImprintScreen'
import IndexScreen from './screens/IndexScreen'
import LessonScreen from './screens/LessonScreen'
import GlobalStateProvider from './helpers/GlobalState'

function App() {
    return (
        <div className="App">
            <GlobalStateProvider>
                <Router>
                    <Route path="/" exact component={IndexScreen} />
                    <Route path="/lesson/:id" exact component={LessonScreen} />
                    <Route path="/chapter/:number" exact component={ChapterRedirect} />
                    <Route path="/dataprotection" exact component={DataProtectionScreen} />
                    <Route path="/imprint" exact component={ImprintScreen} />
                </Router>
            </GlobalStateProvider>
        </div>
    )
}

export default App
