import React, { Component } from 'react'
import { Button, Form, Modal } from 'react-bootstrap'
import { RouteComponentProps } from 'react-router-dom'

import LessonViewPort from '../components/LessonViewPort'
import ClientLayout, { ClientData } from '../layout/ClientLayout'
import { trackEvent, EVENT_CATEGORY, EVENT_ACTION } from '../helpers/track'

interface State {
    data: ClientData
    voucher?: string
    voucherError: boolean
}

interface RouteParams {
    id: string
}

type Props = RouteComponentProps<RouteParams>

export default class LessonScreen extends Component<Props, State> {
    state = {
        voucher: undefined,
        voucherError: false,
    } as State

    private trackedProgress = 0

    onEntry = () => {
        if (this.state.voucher && this.isVoucherCorrect(this.state.voucher)) {
            localStorage.setItem('voucherCode', this.state.voucher)
            this.forceUpdate()
        } else {
            this.setState({ voucherError: true })
        }
    }

    isVoucherCorrect = (code: string) => {
        const { data } = this.state

        if (data && data.vouchers.length) {
            return data.vouchers.filter((voucher) => voucher.code === code).length > 0
        } else {
            return false
        }
    }

    hasStoredVoucherCode = () => {
        const code = localStorage.getItem('voucherCode')

        if (code && this.isVoucherCorrect(code)) {
            return true
        } else {
            return false
        }
    }

    renderVoucher = () => {
        return (
            <Modal show={true} centered>
                <Modal.Header>
                    <h2>Ihr Zugangscode</h2>
                </Modal.Header>
                <Form onSubmit={this.onEntry}>
                    <Modal.Body>
                        <Form.Group controlId="formBasicPassword">
                            <Form.Label>Bitte geben Sie Ihren Zugangscode ein</Form.Label>

                            <Form.Control
                                placeholder="Code"
                                onChange={(e) => this.setState({ voucher: e.target.value, voucherError: false })}
                            />

                            {this.state.voucherError ? <Form.Text>Dieser Code ist ungültig.</Form.Text> : null}
                        </Form.Group>
                    </Modal.Body>

                    <Modal.Footer>
                        <Button
                            type="submit"
                            style={{
                                backgroundColor: this.state.data?.theme_color,
                                borderColor: 'darkgray',
                            }}
                        >
                            Eingang
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
        )
    }

    render() {
        const hasAccess: boolean = !this.state.data?.uses_voucher || this.hasStoredVoucherCode()

        return (
            <ClientLayout
                footer={false}
                basicLayoutProps={
                    {
                        props: {
                            container: {
                                className: 'main-content-container lesson-view-container',
                            },
                        },
                        readable: hasAccess,
                    } as any
                }
                onReceiveData={(data) => this.setState({ data })}
            >
                {hasAccess ? null : this.hasStoredVoucherCode() ? null : this.renderVoucher()}
                <LessonViewPort onProgress={this.onProgress} id={this.props.match.params.id} />
            </ClientLayout>
        )
    }

    private onProgress = (progress: number) => {
        const { data } = this.state
        const { id } = this.props.match.params

        const intProgress = Math.round(progress)

        if (progress < 1 || !data || intProgress <= this.trackedProgress) return

        const [lessonItem] = data.lessons.filter((lesson) => lesson.id === id)
        this.trackedProgress = intProgress
        trackEvent(EVENT_CATEGORY.LESSON, EVENT_ACTION.PROGRESS, lessonItem.header, progress)
    }
}
