import React, { Component } from 'react'
import { Button, Modal } from 'react-bootstrap'

import { getClientUrl } from '../../helpers/url'

interface Props {
    open: boolean
    onTermsAgree: () => void
    onTermsDecline: () => void
}
interface State {
    terms: string
}

export default class TermsAndConditions extends Component<Props, State> {
    state = {} as State

    async componentDidMount() {
        const response = await fetch(getClientUrl('/terms'))
        const { text } = await response.json()

        this.setState({
            terms: text,
        })
    }

    render() {
        const { terms } = this.state
        const { onTermsAgree, onTermsDecline } = this.props
        return (
            <Modal show={this.props.open} onHide={onTermsDecline}>
                <Modal.Header closeButton>
                    <Modal.Title>Terms & Conditions</Modal.Title>
                </Modal.Header>

                <Modal.Body>{terms}</Modal.Body>

                <Modal.Footer>
                    <Button variant="secondary" onClick={onTermsDecline}>
                        Decline
                    </Button>
                    <Button variant="primary" onClick={onTermsAgree}>
                        Accept
                    </Button>
                </Modal.Footer>
            </Modal>
        )
    }
}
